/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');


html {
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #ECECEC;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #C2C2C2;
    border: 3px solid transparent !important;
    border-radius: 0px;
    background-clip: content-box;
  }

  * {
    scrollbar-color: #C2C2C2 #ECECEC;
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: auto;
  }

}

body {
  font-family: 'Urbanist', sans-serif !important;
  background-color: #F6F6F6 !important;
}


option {
  color: black;
}

