:root {
  --brand-color: rgba(0, 162, 173, 1);
  --border-color: rgba(0, 0, 0, 0.2);
}

.ecl-border-ui {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, rgba(195, 183, 229, 1), rgba(255, 235, 161, 1), rgba(195, 183, 229, 1), rgba(160, 220, 224, 1)) border-box !important;
  border-radius: 0.4375rem !important;
  border: 1px solid transparent !important;
  &:hover{
    border: 1px solid #00A2AD !important;
  }
  &:disabled{
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
  }
}

.box-right-section-layout{
  float: right;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  position: relative;
  max-height: 100%;
  width: calc(100% - 250px);
  max-width: calc(100% - 250px);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition-duration: .2s, .2s, .35s;
  transition-property:top, bottom, width;
  transition-timing-function: linear, linear, ease;
}

.box-fixed-header-container{
  height: max-content;
  position: fixed;
  box-shadow: none;
  background-color: rgba(246, 246, 246, 1);
  border-color: transparent;
  filter: none;
  backdrop-filter: blur(0px);
  background-position: center;
  background-size: cover;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-duration: 0.25s, 0.25s, 0.25s, 0s;
  transition-property: box-shadow, background-color, filter, border;
  transition-timing-function: linear, linear, linear, linear;
  display: flex;
  align-items: center;
  min-height: 75px;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  line-height: 25.6px;
  margin: auto;
  padding-bottom: 20px;
  right: 30px;
  padding-left: 12px;
  padding-right: 10px;
  padding-top: 30px;
  top: 0;
  width: calc(100vw - 320px);
}

.vertical-divider-line {
  color: rgba(0, 0, 0, 0.3);
}

.pagination-container {
  .ecl-border-ui {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to right, rgba(195, 183, 229, 1), rgba(255, 235, 161, 1), rgba(195, 183, 229, 1), rgba(160, 220, 224, 1)) border-box !important;
    border-radius: 0.375rem !important;
    border: 1px solid transparent;

  &:hover{
    border: 1px solid #00A2AD !important;
  }
  &:focus{
    box-shadow: 0 0 0 1px #00A2AD !important;
  }
  }

  .vertical-divider-line {
    color: rgba(0, 0, 0, 0.07);
  }

  .button {
    color: rgba(0, 0, 0, 0.2);
  }

}

.chakra-checkbox__control[data-disabled] {
  background-color: rgba(0, 0, 0, 0.07) !important;
  border-color: rgba(0, 0, 0, 0.07) !important;
}

.selected-value-box-ui {
  background-color: #00A2AD;
  border-radius: 7px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #00A2AD;
  cursor: default;
}

.selected-value-box-ui svg path {
  fill: rgba(246, 246, 246, 0.8);
}

.not-selected-value-box-ui {
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

.green-btn {
  border-radius: 7px !important;
  font-weight: 500;
  border: 1px solid #00A2AD !important;
  background: #00A2AD !important;
  color: white !important;

  &:disabled{
    background-color:rgba(0, 0, 0, 0.07) !important;
    color:rgba(0, 0, 0, 0.4) !important; ;
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
    opacity: unset !important;
  }
}

.red-border-btn {
  border-radius: 7px !important;
  border: 1px solid #E04F4F;
  background: rgba(0, 0, 0, 0.07) !important;
  color: #E04F4F;
}

.center-with-position-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button.disabled {
  color: #ebebeb;
  cursor: not-allowed;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.table-header {
  padding: 1rem !important;
  white-space: nowrap;
  cursor: default;
  border-color: rgba(0, 0, 0, 0.1) !important;

  .table-header-text {
    font-size: 12px !important;
    color: #00A2AD !important;
    font-family: 'Urbanist', sans-serif !important;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    width: 100%;
    gap: 12px;
  }
}

.table-cell {
  padding: 0.5rem 1rem !important;
  white-space: nowrap;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  font-size: 13px !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  cursor: default !important;
}

.Toastify__toast-container {
  width: 420px !important;

  .Toastify__close-button{
    width: 20px;
    height: 20px;
    color: #E04F4F;
    border: 1px solid #E04F4F;
    padding: 0px 2px;
    border-radius: 2px;
    margin-left: 10px;

    svg{
      width: 16px;
      height: 16px;
    }
  }

  .Toastify__toast-body {
    font-family: 'Urbanist', sans-serif !important;
    font-size: 14px !important;
  }

  #successId {
    .Toastify__progress-bar {
      background-color: #00a2ad;
    }
  }

  #errorId {
    .Toastify__progress-bar {
      background-color: #E04F4F;
    }
  }

  #successInApplicationTransactionsDownload{
    .Toastify__progress-bar {
      background-color: #00a2ad;
    }
  }

  #errorInApplicationTransactionsDownload{
    .Toastify__progress-bar {
      background-color: #E04F4F;
    }
  }

  #successInDecisionTransactionsDownload{
    .Toastify__progress-bar {
      background-color: #00a2ad;
    }
  }

  #errorInDecisionTransactionsDownload{
    .Toastify__progress-bar {
      background-color: #E04F4F;
    }
  }







}

.logout-btn{
  font-weight: 600;
  font-size: 14px !important;
  border: 1px solid #E04F4F;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 7px !important;
}
.go-back-btn{
  font-weight: 600;
  font-size: 14px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 7px !important;
}

.download-btn{
  font-weight: 600;
  font-size: 14px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 7px !important;
}

.label-select{
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);

}
.list-selected{
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);

}

.view-detail-btn{
  border-radius: 7px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 0 40px !important;
  border: 1px solid #00A2AD !important;
  background: #00A2AD !important;
  color: white !important;

  &:disabled{
    background-color:rgba(0, 0, 0, 0.07) !important;
    color:rgba(0, 0, 0, 0.4) !important; ;
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
    opacity: unset !important;
  }
}
.chakra-checkbox {
  .chakra-checkbox__control[data-checked] {
    background: #00A2AD !important;
    border-color: #00A2AD !important;
    color: var(--chakra-colors-white);
  }
}

.divider{
    border: 0;
    border-color: inherit;
    border-style: solid;
    border-bottom-width: 1px;
    width: 100%;
    background-color:rgba(0, 0, 0, 0.2) ;
}
.disable{
  background-color:rgba(0, 0, 0, 0.4) !important;
  border: 1px solid rgba(0, 0, 0, 0.07) !important;
}

/* .p-component {
  font-family: 'Urbanist', sans-serif !important;
}

.p-component .p-treetable-header {
  font-weight: 600;
  font-size: 13px !important;
  color: rgba(0, 162, 173, 1);
  border-radius: 15px 15px 0 0;
  background-color: #ffffff;
}

.p-component table {
  table-layout: auto !important;
}

.p-component .p-treetable-wrapper {
  border-radius: 0 0 15px 15px;
}

.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-thead tr th {
  border-color: rgba(0, 0, 0, 0.07) !important;
  border-width: 1px 1px 1px 0 !important;
  padding: 10px 16px;
}

.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-thead tr th div {
  width: max-content;
}

.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-thead tr th div .p-column-title {
  font-weight: 600;
  font-size: 12px !important;
  color: rgba(0, 162, 173, 1);
}

.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-tbody .first-column {
  font-weight: 700;
  font-size: 13px;
  color: rgba(0, 162, 173, 1);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-tbody tr td {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  padding: 4px 16px;
  border-width: 0 1px 1px 0 !important;
  border-color: rgba(0, 0, 0, 0.07) !important;
  white-space: nowrap;
}

.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-tbody tr td button:focus {
  box-shadow: none;
}

.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-tbody tr td button span {
  display: flex;
  align-items: center;
  justify-content: center;
}


.p-component .p-treetable-wrapper .p-treetable-table .p-treetable-tbody .no-children-style td {
  color: rgba(0, 0, 0, 0.9);
} */

.last-refresh-at{
  font-size: 13px !important;
  color: #00A2AD !important;
  font-family: 'Urbanist', sans-serif !important;
  font-weight: 600;
  width: 100%;
}

tbody > .css-0:hover{
background-color: rgba(0, 162, 173, 0.05);
}

.decision-summary-table .chakra-text {
  width: 100%;
}


.custom-debt-popup-progress{
  border-radius: 7px;
  width: 100%;
  height: 5px;
}
.custom-debt-popup-progress::-webkit-progress-bar {
  background-color: rgba(0, 0, 0, 0.20);
  border-radius: 7px;
}
.custom-debt-popup-progress::-webkit-progress-value {
  background-color: rgba(0, 162, 173, 1);
  border-radius: 7px;
}




/* ------------scrollable tree table ------------------- */

.p-component {
  font-family: 'Urbanist', sans-serif !important;
}

.p-component .p-treetable-header {
  font-weight: 600;
  font-size: 13px !important;
  color: rgba(0, 162, 173, 1);
  border-radius: 15px 15px 0 0;
  background-color: #ffffff;
}

.p-component table {
  table-layout: fixed !important;
}

.p-component .p-treetable-scrollable-wrapper {
  border-radius: 0 0 15px 15px;
  overflow-y: hidden;
}

.p-component .p-treetable-scrollable-wrapper .p-treetable-scrollable-header-table .p-treetable-thead tr th {
  border-color: rgba(0, 0, 0, 0.07) !important;
  border-width: 1px 1px 1px 0 !important;
  padding: 10px 16px;
  height: 45px;
  width: 130px;
}



.p-component .p-treetable-scrollable-wrapper .p-treetable-scrollable-header-table .p-treetable-thead tr th div {
  width: max-content;
}

.p-component .p-treetable-scrollable-wrapper .p-treetable-scrollable-header-table .p-treetable-thead tr th div .p-column-title {
  font-weight: 600;
  font-size: 12px !important;
  color: rgba(0, 162, 173, 1);
}

.p-component .p-treetable-scrollable-wrapper .p-treetable-scrollable-header-table .p-treetable-scrollable-body-table .first-column {
  font-weight: 700;
  font-size: 13px;
  color: rgba(0, 162, 173, 1);
  display: flex;
  align-items: center;
  white-space: wrap !important;
}

.p-component .p-treetable-scrollable-wrapper .p-treetable-scrollable-header-table .p-treetable-scrollable-body-table tr td {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  padding: 4px 16px;
  border-width: 0 1px 1px 0 !important;
  border-color: rgba(0, 0, 0, 0.07) !important;
  white-space: nowrap;
}

.p-component .p-treetable-scrollable-wrapper .p-treetable-scrollable-header-table .p-treetable-scrollable-body-table tr td button:focus {
  box-shadow: none;
}

.p-component .p-treetable-scrollable-wrapper .p-treetable-scrollable-header-table .p-treetable-scrollable-body-table tr td button span {
  display: flex;
  align-items: center;
  justify-content: center;
}


.p-treetable-scrollable-body .p-treetable-scrollable-body-table .p-treetable-tbody tr td {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  padding: 4px 16px;
  border-width: 0 1px 1px 0 !important;
  border-color: rgba(0, 0, 0, 0.07) !important;
  white-space: nowrap;
  height: 45px;
  width: 130px;
}
.p-treetable-scrollable-body .p-treetable-scrollable-body-table .p-treetable-tbody tr td button span {
  align-items: center;
  display: flex;
  justify-content: center;
}
.p-treetable-scrollable-body .p-treetable-scrollable-body-table .p-treetable-tbody tr td button:focus {
  box-shadow: none;
}
.p-treetable-scrollable-body .p-treetable-scrollable-body-table .p-treetable-tbody .no-children-style td {
  color: rgba(0, 0, 0, 0.9);
}



.p-treetable-scrollable-body .p-treetable-scrollable-body-table .p-treetable-tbody .first-column {
  align-items: center;
  color: #00a2ad;
  display: flex;
  font-size: 13px;
  font-weight: 700;
  white-space: wrap;
  width: unset !important;
  /* width:max-content !important; */
}

/* .p-treetable-unfrozen-view .p-treetable-scrollable-body{
  overflow-y: auto !important;
} */



.p-treetable-unfrozen-view > .p-treetable-scrollable-header{
  margin-right: 10px;
}


.table-nested-row-style{
  padding: 12px 0;
  border-bottom:  1px solid rgba(0, 0, 0, 0.07);
}