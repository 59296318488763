:root {
    --brand-color: rgba(0, 162, 173, 1);
    --divider-line-color: rgba(0, 0, 0, 0.2);
    --border-color: rgba(0, 0, 0, 0.7);
}




.date-range-picker-container {
    flex-direction: row-reverse;
    background-color: white;


    .rdrDefinedRangesWrapper {
        margin: auto;
        width: max-content !important;
        border-left: 1px solid var(--divider-line-color);
        border-right: none !important;
        padding-left: 20px;


        .rdrStaticRanges {
            gap: 24px !important;
            align-items: center !important;

            .rdrStaticRangeSelected{
                .rdrStaticRangeLabel {
                    background: rgba(0, 162, 173, 1);
                    border: 1px solid rgba(0, 162, 173, 1);
                    color: #ffffff !important;
                    &:hover {
                    background: rgba(0, 162, 173, 1) !important;
                    border: 1px solid rgba(0, 162, 173, 1) !important;
                    color: #ffffff !important;
                    }
                }
            }

            .rdrStaticRange {      
                width: 150px !important;
                border-radius: 7px;
                background: rgba(0, 0, 0, 0.07);

                .rdrStaticRangeLabel {
                    border: 1px solid rgba(0, 0, 0, 0.07);
                    color: rgba(0, 0, 0, 0.5);
                    font-weight: 500;
                    width: 100% !important;
                    text-align: center;
                    border-radius: 7px;

                }

                .rdrStaticRangeLabel:hover {
                    background: rgba(0, 162, 173, 0.4);
                    border: 1px solid rgba(0, 162, 173, 0.4);
                    color: #ffffff;
                }

            }



        }

        .rdrStaticRanges button:nth-child(1) {
            border: none !important;
        }
        .rdrStaticRanges button:nth-child(1):hover {
            background-color: unset !important;
            border: none !important;
        }
        .rdrStaticRanges button:nth-child(1) .rdrStaticRangeLabel  {
            text-align: left !important;
            background-color: #ffffff;
            padding: 0px;
            color: #00A2AD !important;
            font-size: 14px;
            font-weight: 600 !important;
            border: none !important;
            cursor: default;
        }
        .rdrStaticRanges button:nth-child(1) .rdrStaticRangeLabel:hover  {
            background-color: unset !important;
            border: none !important;
        }

        .rdrInputRanges {
            display: none !important;
        }
    }


    .rdrCalendarWrapper {
        .rdrDateDisplayWrapper {
            background-color: white;
        }

        .rdrMonthAndYearWrapper {
            width: max-content !important;

            .rdrPprevButton{
                margin: 0 0.55em;
                i{
                    border-color: transparent  #00A2AD transparent transparent;
                }
            }

            .rdrNextButton{
                margin: 0 0.55em;
                i{
                    border-color: transparent transparent transparent  #00A2AD; 
                }
            }


            .rdrMonthPicker {
                background: linear-gradient(white, white) padding-box,
                    linear-gradient(to right, rgba(195, 183, 229, 1), rgba(255, 235, 161, 1), rgba(195, 183, 229, 1), rgba(160, 220, 224, 1)) border-box !important;
                border-radius: 0.375rem !important;
                border: 1px solid transparent !important;
                select {
                    padding: 0px 20px 0px 10px !important;
                    font-size: 12px;
                    font-weight: 600;
                }
            }

            .rdrYearPicker {
                background: linear-gradient(white, white) padding-box,
                    linear-gradient(to right, rgba(195, 183, 229, 1), rgba(255, 235, 161, 1), rgba(195, 183, 229, 1), rgba(160, 220, 224, 1)) border-box !important;
                border-radius: 0.375rem !important;
                border: 1px solid transparent !important;
                select {
                    padding: 0px 20px 0px 10px !important;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .rdrMonthsHorizontal {
            .rdrMonth {
                border-right: 1.5px solid var(--divider-line-color);
                width: 20em !important;

                .rdrMonthName {
                    color: rgba(0, 0, 0, 0.9) !important;
                    padding-left: 6px;
                    font-size: 14px;
                    font-weight: 700;
                }

                .rdrWeekDays {
                    padding-bottom: 10px !important;

                    .rdrWeekDay {
                        line-height: 1 !important;
                        color: rgba(0, 0, 0, 0.4) !important;
                        font-weight: 600;
                    }

                }

                .rdrDays {
                    .rdrDayPassive {
                        .rdrDayNumber span {
                            color: rgba(0, 0, 0, 0.2) !important;
                            font-weight: 600;
                            font-size: 12px;
                        }

                    }
                    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span {
                        color: #ffffff !important;
                    }
                    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
                        color: #ffffff !important;
                    }

                    .rdrDay {
                        line-height: 0 !important;
                        height: 2.5em !important;

                        .rdrDayNumber span {
                            color: rgba(0, 0, 0, 0.9);
                            font-weight: 600;
                        }

                        .rdrStartEdge {
                            color: #00A2AD !important;
                        }

                        .rdrEndEdge {
                            color: #00A2AD !important;
                        }

                     
                    }

                    .rdrDayToday .rdrDayNumber span:after {
                        bottom: 2px !important;
                        background-color:rgba(0, 0, 0, 0.4) !important;
                    }
                }
            }
        }

    }

}

.date-range-picker-container .rdrCalendarWrapper .rdrMonthsHorizontal .rdrMonth:first-child {
    border-right: 1px solid var(--divider-line-color);
}

.date-range-picker-container .rdrCalendarWrapper .rdrMonthsHorizontal .rdrMonth:nth-child(2) {
    border-right: 1px solid transparent;
}


.custom-choose-element {
    color: red !important;
    font-weight: bold !important;
}

.only-range-calender-container{
    min-width: 150px !important;
    padding: 2px 2px 0px 2px !important;
    .only-range-calender{
        width: 100% !important;
        border-right: none;
        .rdrStaticRanges{
            .rdrStaticRangeSelected{
                color: #00A2AD !important;
                .rdrStaticRangeLabel{
                    color: #00A2AD !important;
                 }
            }
            .rdrStaticRange{
                border-color:rgba(0, 0, 0, 0.2) !important ;
                .rdrStaticRangeLabel{
                   padding: 8px;
                   color: rgba(0, 0, 0, 0.9);
                   font-size: 13px;
                }
            }
        }
        .rdrInputRanges {
            display: none !important;
        }
    }

}
